.list-group-item-action {
    color: #495057 !important;
}

.dropdown-item {
    color: #212529 !important;
}

.login-card-8{
    border-radius: 8px !important;
}

.m50{
   margin: 50px;
}

.m30{
    margin: 30px;
}

.ml50{
    margin: 0px 50px;
}

.videocontainer{
    margin-bottom: 30px;
}

.parent{
    display: grid;
    grid-template-rows: auto 1fr auto;
}

.article img{
    max-width: 100% !important;
    margin: 0px 10px;
}

