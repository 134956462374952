.avatar {
    width: 150px;
    height: 150px !important;
}

.nodec:hover {
    text-decoration: none;
}

.anews {
    color: white !important;
}

.anews:hover {
    color: #a7caff !important;
}

.py-4{
    margin-bottom: 50px;
}

.newsgray{
    font-weight: 500;
    font-style: italic;
    font-size: 14.0px;
    color: rgba(157, 157, 157, 1.0);
    text-align: center;
    line-height: 22.0px;
}
