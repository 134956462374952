/*
- Name: megamenu.js - style.css
- Version: 1.0
- Latest update: 29.01.2016.
- Author: Mario Loncarek
- Author web site: http://marioloncarek.com
*/


/* ––––––––––––––––––––––––––––––––––––––––––––––––––
/* –––––––––––––––––––––––––––––––––––––––––––––––––
Body - not related to megamenu
–––––––––––––––––––––––––––––––––––––––––––––––––– */

body {
    font-family: 'Source Sans Pro', sans-serif;
}

* {
    box-sizing: border-box;
}

a {
    color: #333;
}

.description {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}


/* ––––––––––––––––––––––––––––––––––––––––––––––––––
megamenu.js STYLE STARTS HERE
–––––––––––––––––––––––––––––––––––––––––––––––––– */


/* ––––––––––––––––––––––––––––––––––––––––––––––––––
Screen style's
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.menu-container {
    width: 100%;
    margin: 0 auto;
    background: rgba(233, 233, 233, 0);
}

.menu-mobile {
    display: none;
    padding: 20px;
}

.menu-mobile:after {
    content: "\f394";
    font-family: "Ionicons";
    font-size: 2.5rem;
    padding: 0;
    float: right;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-25%);
    -ms-transform: translateY(-25%);
    transform: translateY(-25%);
}

.menu-dropdown-icon:before {
    content: "\f489";
    font-family: "Ionicons";
    display: none;
    cursor: pointer;
    float: right;
    padding: 0.5em 0.8em;
    background: #fff;
    color: #333;
}

.menu > ul {
    margin: 0 auto;
    width: 100%;
    list-style: none;
    padding: 0;
    position: relative;
    /* IF .menu position=relative -> ul = container width, ELSE ul = 100% width */
    box-sizing: border-box;
}

.nopadding{
    padding-left: 0px !important;
}

.menu > ul:before,
.menu > ul:after {
    content: "";
    display: table;
}

.menu > ul:after {
    clear: both;
}

.menu > ul > li {
    float: left;
    background: rgba(233, 233, 233, 0);
    padding: 0;
    margin: 0;
}

.menu > ul > li a {
    text-decoration: none;
    padding: 0.5em 1.2em;
    display: grid;
    color: white !important;
    text-transform: uppercase;
    font-weight: bold;
}
.nolist{
    list-style: none;
    padding: 0 !important;
}


.menu > ul > li a:hover {
    color: #a7caff !important;
    background: unset !important;
}

.menu > ul > li:hover {
    background: #5e498c;
}



.menu > ul > li > ul {
    display: none;
    width: 100%;
    background: #5e498c;
    padding: 20px;
    position: absolute;
    z-index: 99;
    left: 0;
    margin: 0;
    list-style: none;
    box-sizing: border-box;
}

.menu > ul > li > ul:before,
.menu > ul > li > ul:after {
    content: "";
    display: table;
}

.menu > ul > li > ul:after {
    clear: both;
}

.menu > ul > li > ul > li {
    margin: 0;
    padding-bottom: 0;
    list-style: none;
    width: 25%;
    background: none;
    float: left;
}

.menu > ul > li > ul > li a {
    color: white !important;
    padding: .2em 0;
    width: 95%;
    display: block;
    border-bottom: 1px solid #ccc;
}

.menu > ul > li > ul > li a:hover{
    background: #5e498c;
    color: #a7caff !important;
}

.menu > ul > li > ul > li > ul {
    display: block;
    padding: 0;
    margin: 10px 0 0;
    list-style: none;
    box-sizing: border-box;
}

.menu > ul > li > ul > li > ul:before,
.menu > ul > li > ul > li > ul:after {
    content: "";
    display: table;
}

.menu > ul > li > ul > li > ul:after {
    clear: both;
}

.menu > ul > li > ul > li > ul > li {
    float: left;
    width: 100%;
    padding: 10px 0;
    margin: 0;
    font-size: .8em;
}

.menu > ul > li > ul > li > ul > li a {
    border: 0;
}

.menu > ul > li > ul.normal-sub {
    width: 300px;
    left: auto;
    padding: 10px 20px;
}

.menu > ul > li > ul.normal-sub > li {
    width: 100%;
}

.menu > ul > li > ul.normal-sub > li a {
    border: 0;
    padding: 1em 0;
}


/* ––––––––––––––––––––––––––––––––––––––––––––––––––
Mobile style's
–––––––––––––––––––––––––––––––––––––––––––––––––– */

@media only screen and (max-width: 959px) {
    .menu-container {
        width: 100%;
    }
    .menu-mobile {
        display: block;
    }
    .menu-dropdown-icon:before {
        display: block;
    }
    .menu > ul {
        display: none;
    }
    .menu > ul > li {
        width: 100%;
        float: none;
        display: block;
    }
    .menu > ul > li a {
        padding: 0.5em;
        width: 100%;
        display: block;
    }
    .menu > ul > li > ul {
        position: relative;
    }
    .menu > ul > li > ul.normal-sub {
        width: 100%;
    }
    .menu > ul > li > ul > li {
        float: none;
        width: 100%;
        margin-top: 20px;
    }
    .menu > ul > li > ul > li:first-child {
        margin: 0;
    }
    .menu > ul > li > ul > li > ul {
        position: relative;
    }
    .menu > ul > li > ul > li > ul > li {
        float: none;
    }
    .menu .show-on-mobile {
        display: block;
    }

    #app > div > nav:nth-child(2) > div > div > div > a
    {
        color: white !important;
        text-decoration: none;
        &:hover{
            text-decoration: none;
        }
    }
}
