.btn-primary{
    background-color: rgba(100, 75, 142, 1.0) !important;
    border-color: rgba(100, 75, 142, 1.0) !important;
}

.login-btn{
    padding: 0.375rem 1.75rem !important;
    font-weight: 500 !important;
}

.login-card{
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 16px;
    box-shadow: 0px 6px 6px rgba(0,0,0,0.04), 0px -3px 6px rgba(0, 0, 0 , 0.04),3px 0px 6px rgba(0,0,0,0.04), -3px 0px 6px rgba(33, 36, 41, 0.04);
}

.vh-15{
    margin-top: 15vh;
}

.card-header{
    background-color: unset !important;
}
