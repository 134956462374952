.bg-orig{
    background-color: #5e498c ;
}
.wh{
    color: white !important;
}

.navbar{
    border-bottom: 2px solid white;
    padding: unset !important;
}

.rounded-circle{
    border: white solid 1.5px;
    padding: 2px;
}
.notification {
    display: grid;
    height: 100%;
    align-content: center;

}

.notification i {
    font-size: 30px;
    margin: auto;
    margin-right: 10px;

}
