.nana{
    font-family: "Roboto", Helvetica, Arial, serif;
    font-weight: 400;
    font-style: normal;
    font-size: 15.0px;
    color: rgba(130, 130, 130, 1.0);
    text-align: left;
    line-height: 20.0px;
    cursor: pointer;
}

.nactive{
    border-bottom: 4px solid  #5e498c;
    font-size: 16.0px;
    color: rgba(33, 36, 41, 1.0);
}
