//.v-application{font-family:Nunito,sans-serif;line-height:1.5}

.v-application a {
    color: unset;
}

.btn-success{
    color: #fff !important;
}

.v-application--wrap{
    min-height: unset !important;
}
